import React, { useState } from "react";
import "./careers.css";
import pr2 from "../../img/smallBorder.png";
import ic1 from "../../img/Group.png";
import ic2 from "../../img/Vector.png";
import ic3 from "../../img/Vector2.png";
import ic1w from "../../img/gr2w.png";
import ic2w from "../../img/v1w.png";
import ic3w from "../../img/v2w.png";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const Careers = () => {
  const [ref, inView] = useInView({ threshold: 0.01 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(20%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(30%)",
    },
    config: { duration: 1000 },
  });
  //   const [isHovered1, setIsHovered1] = useState(true);

  //   const handleHover1 = () => {
  //     setIsHovered1(!isHovered1);
  //   };

  //   const [isHovered2, setIsHovered2] = useState(true);

  //   const handleHover2 = () => {
  //     setIsHovered2(!isHovered2);
  //   };
  //   const [isHovered3, setIsHovered3] = useState(true);

  //   const handleHover3 = () => {
  //     setIsHovered3(!isHovered3);
  //   };

  //   const [isHovered4, setIsHovered4] = useState(true);

  //   const handleHover4 = () => {
  //     setIsHovered4(!isHovered4);
  //   };
  //   const [isHovered5, setIsHovered5] = useState(true);

  //   const handleHover5 = () => {
  //     setIsHovered5(!isHovered5);
  //   };

  return (
    <div id="careers" className="mainContainer-c">
      <div className="mainHeader-c">Careers</div>
      {/* <div className=""> */}
      <img
        className="smallBorder-c"
        style={{ width: "5%", objectFit: "cover" }}
        src={pr2}
        alt="pr2"
      />
      <animated.div ref={ref} style={{ ...props3 }} className="careers-content">
        <h1>Join Our Dynamic Team at AmpliWorks Technologies</h1>
        <p>
          At AmpliWorks Technologies, we believe in fostering a work environment
          that nurtures innovation, collaboration, and professional growth. Our
          excellence is reflected not only in the transformative solutions we
          deliver to our clients but also in the exceptional team that makes it
          all possible and our commitment beyond contract.
        </p>
        <h1>Why Choose AmpliWorks Technologies?</h1>
      </animated.div>
      <div className="secondRow-c">
        <div className="cardMain-c">
          <h2 className="headerText-c">Innovation at the Core</h2>
          <p
            className="paraText-c"
            style={{ textAlign: "start", marginRight: "0rem" }}
          >
            Join a team that thrives on cutting-edge technology and is dedicated
            to pushing the boundaries of what's possible. AmpliWorks is at the
            forefront of HCM transformation, digital solutions, and intelligent
            automation.
          </p>
        </div>
        <div className="cardMain-c">
          <h2 className="headerText-c">Global Impact</h2>
          {/* <h2 className="headerText2-c">UKG KRONOS</h2> */}
          <p
            className="paraText-c"
            style={{ textAlign: "start", marginRight: "0rem" }}
          >
            Contribute to projects that have a global reach. With offices in the
            USA (Wilmington, Delaware) and Bangalore (India), AmpliWorks
            Technologies serves clients around the clock, providing an
            opportunity to work on diverse and challenging assignments.
          </p>
        </div>
      </div>
      <div className="secondRow-c">
        <div className="cardMain-c">
          <h2 className="headerText-c">Professional Growth</h2>
          {/* <h2 className="headerText2-c">UKG KRONOS</h2> */}
          <p
            className="paraText-c"
            style={{ textAlign: "start", marginRight: "0rem" }}
          >
            AmpliWorks Technologies is committed to the growth and development
            of its employees. We provide continuous learning opportunities and a
            culture that encourages skill enhancement and career progression.
          </p>
        </div>
        <div className="cardMain-c">
          <h2 className="headerText-c"> Inclusive Culture</h2>
          {/* <h2 className="headerText2-c">UKG KRONOS</h2> */}
          <p
            className="paraText-c"
            style={{ textAlign: "start", marginRight: "0rem" }}
          >
            Join a diverse and inclusive workplace where every team member is
            valued. We believe that diverse perspectives drive innovation, and
            we actively foster an environment where everyone can contribute and
            thrive.
          </p>
        </div>
      </div>

      <div className="careers-content">
        <h1>Open Positions</h1>
      </div>

      <div className="secondRow-c">
        <a className="hyperLink-card" href="/job1">
          <div className="cardMain-c-job">
            <h2 style={{ padding: "1rem" }} className="headerText-c">
              Integration Consultant (Dell Boomi, SAP CPI, SQL, RDBMS)
            </h2>
            <p
              className="paraText-c"
              style={{ textAlign: "start", marginRight: "0rem" }}
            >
              We are seeking a skilled and experienced Integration Consultant
              with expertise in Dell Boomi, SAP CPI (Cloud Platform
              Integration), SQL, and RDBMS (Relational Database Management
              Systems) to join our team...{" "}
              <span className="readmore">Read more</span>
            </p>
          </div>
        </a>
        <a className="hyperLink-card" href="/job2">
          <div className="cardMain-c-job">
            <h2 style={{ padding: "1rem" }} className="headerText-c">
              Success Factors – Senior Consultant
            </h2>
            <p
              className="paraText-c"
              style={{ textAlign: "start", marginRight: "0rem" }}
            >
              The Consultant will be responsible for discussions with the
              clients in designing and delivering solutions to meet Client
              requirements using standard modules of SAP SF Conduct requirements
              workshops... <span className="readmore">Read more</span>
            </p>
          </div>
        </a>
      </div>
      <div className="secondRow-c">
        <a className="hyperLink-card" href="/job3">
          <div className="cardMain-c-job">
            <h2 style={{ padding: "1rem" }} className="headerText-c">
              Full Stack Developer (MERN, React/NodeJS)
            </h2>
            <p
              className="paraText-c"
              style={{ textAlign: "start", marginRight: "0rem" }}
            >
              We are looking for highly skilled programmers with experience
              building web applications. MERN Developers are responsible
              for understanding user requirements and business objectives,
              participating in the estimation process....{" "}
              <span className="readmore">Read more</span>
            </p>
          </div>
        </a>
      </div>

      <div className="careers-content">
        <h1> How to Apply</h1>
        <p>
          Please send your resume and a cover letter to
          <span style={{ color: "blue", cursor: "pointer" }}>
            &nbsp;careers@ampliworks.com
          </span>
          .
        </p>
        <p>
          Amplify your career with AmpliWorks Technologies — where innovation
          meets excellence!
        </p>
        <h1></h1>
      </div>

      <div style={{ height: "100px" }} class="wrp">
        <div class="portada"></div>
        <div class="contenido"></div>
        <div class="curveado">{/* <img src={curve3} /> */}</div>
      </div>
    </div>
  );
};

export default Careers;
