import React from "react";
import pr3 from "../../img/Rectangle 14.png";
import pr2 from "../../img/smallBorder.png";

import "./whyUs.css";

function whyUs() {
  return (
    <div id="WhyUs" className="whyUsMain">
      <div className="whyUsLeft" style={{ backgroundColor: "#E7E7E7" }}>
        {/* <div className="imageContainer">
                    <img style={{ width: '80%', objectFit: 'contain', marginLeft: '6rem' }} src={pr2} alt="pr2" />
                   
                </div>
                <div className="imageContainer2">
                    <img style={{ width: '100%', objectFit: 'contain', }} src={pr3} alt="pr2" />
                   
                </div> */}
        {/* <div className="imageContainer3">
                    <img style={{ width: '100%', objectFit: 'contain', }} src={pr4} alt="pr2" />
                   
                </div> */}

        <img
          style={{ width: "100%", objectFit: "cover" }}
          src={pr3}
          alt="pr2"
        />
      </div>
      <div className="whyUsRight">
        <h1 className="whyUsHeader">WHY US</h1>
        <img
          style={{
            width: "15%",
            objectFit: "contain",
            marginTop: "-1rem",
            marginBottom: "0.4rem",
          }}
          src={pr2}
          alt="pr2"
        />

        <div className="whyUsText">
          <p
            style={{
              wordWrap: "break-word", // Wrap long words to avoid overflow
              whiteSpace: "normal", // Allow text to wrap and break lines
            }}
          >
            Our HR domain & UKG solution experts have proven experience to
            implement Workforce Management Solutions (WFM) in an accelerated
            manner with minimum customizations leveraging industry specific best
            practices. We integrate seamlessly using Dell Boomi or WIM or SAP
            HANA Cloud with other 3rd party ERP systems.
          </p>
          <p
            style={{
              wordWrap: "break-word", // Wrap long words to avoid overflow
              whiteSpace: "normal", // Allow text to wrap and break lines
            }}
          >
            Our services enable you to rapidly start your journey, be it a
            tactical or a transformational business need. Our consultants help
            you identify the risks, leakages, and opportunities to improve
            productivity. Our expert team will deliver through a cost-effective
            and proven service model to deliver the best Roi.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default whyUs;
