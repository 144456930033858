import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
export default function DevelopmentServices() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
       
      />
      <HcmData
         heading="Development & Services"
       
      />
      <HcmData
        heading="Cloud & Mobile App Development"
        text="Our expert team specializes in creating cutting-edge cloud-based and mobile applications that cater to your unique business needs. We harness the power of the cloud to provide scalable, responsive, and feature-rich apps that deliver exceptional user experiences across various devices and platforms."
      />

      <HcmData
        heading="Cloud Migration & DevOps"
        text="Seamlessly transition your existing systems to the cloud with our top-tier cloud migration and DevOps solutions. Our experienced professionals ensure a smooth migration process, optimizing your infrastructure for scalability, security, and efficiency. By embracing DevOps practices, we enhance collaboration and accelerate your development cycles.        "
      />
      <HcmData
        heading="Unified UX Design"
        text="Elevate your digital presence with our unified user experience (UX) design services. We craft cohesive and intuitive user interfaces that streamline user interactions across all touchpoints. Our design approach seamlessly integrates aesthetics and functionality, resulting in engaging and user-friendly applications."
      />
      <HcmData
        heading="DevOps"
        text="Our DevOps services empower your development and operations teams to collaborate effectively, reducing development cycles and increasing deployment frequency. We implement modern DevOps practices to automate processes, enhance software quality, and foster a culture of continuous improvement, enabling you to deliver software faster and with greater confidence."
      />
      <Contact_us />
      <Footer />
    </div>
  );
}
