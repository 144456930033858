import React from "react";
import "./Footer.css";

function footer() {
  return (
    <div className="footerContainer">
      <div className="footerLeft">
        <p>© Copyright 2023 | AmpliWorks</p>
      </div>
      <div className="footerRight">
        <a href="/">
          <i class="icon-f fa-brands fa-linkedin"></i>
        </a>
        {/* <a href="/">
          <i className="icon-f fa-brands fa-square-twitter"></i>
        </a>
        <a href="/">
          <i className="icon-f fa-brands fa-pinterest"></i>
        </a>
        <a href="/">
          <i className="icon-f fa-brands fa-square-instagram"></i>
        </a> */}
      </div>
    </div>
  );
}

export default footer;
