import "./hcm.css";
import HcmData from "./hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function Hcm() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
       
      />
      <HcmData
        heading="HCM Transformation"
        text="AmpliWorks is focused on Transformation of Human Capital Management (HCM) and have been serving clients for UKG/Kronos, SAP Success Factors and Oracle HCM. With deep expertise across Manufacturing, Healthcare, Retail, Hospitality, Services and Distribution industry sectors our services span across consulting, implementation, integration, cloud migration, customization, support and user training."
      />
      <HcmData text=" We provide powerful actionable HCM Insights enabled by Analytics with Power BI." />
      <Contact_us />
      <Footer />
    </div>
  );
}
