import "./customers.css";
// import  from '../../img/profile1.jpg'
import profile2 from "../../img/1.png";
import profile3 from "../../img/12.png";
import profile4 from "../../img/3.png";
import profile1 from "../../img/4.png";
import pr2 from "../../img/smallBorder.png";
import Marquee from "react-fast-marquee";

// import profile5 from '../../img/profile5.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import l1 from "../../img/1.png";
import l2 from "../../img/2.png";
import l3 from "../../img/3.png";
import l4 from "../../img/4.png";
import l5 from "../../img/5.png";
import l6 from "../../img/6.png";
import l7 from "../../img/7.png";
import l8 from "../../img/8.png";
import l9 from "../../img/Natco-Logo.png";
import l10 from "../../img/bharat_forge.png";
import l11 from "../../img/solenis.png";
import l12 from "../../img/12_1.png";
import l13 from "../../img/sterlite1.png";

import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

export default function Customers() {
  const [ref, inView] = useInView({ threshold: 0.01 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(20%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(30%)",
    },
    config: { duration: 1000 },
  });
  const clients = [
    {
      img: profile4,
      review:
        "I had a very pleasant experience with the AmpliWorks team on the complex HR transformation projects for our workforce. The team went to extra mile and finished the project before the agreed timelines. Every person involved in the project, from project managers to software developers, was thoroughly knowledgeable. However, there is always room for improvement, and I believe AmpliWorks could benefit from more transparency in their communication with customers. Overall, I would highly recommend this team of professionals to everyone who wants to take up the HR transformation initiatives.",
      name: "Narendra Kumar",
      designation:
        "Head -Employee Relations & Compliance for HCCB from 2017 -2023",
    },
    // {
    //   img: profile2,
    //   review:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero reprehenderit tenetur voluptate? Nemo ad delectus doloremque aspernatur nostrum, accusantium odit, libero pariatur nesciunt illo harum quasi officiis tenetur molestias!",
    // },

    // {
    //   img: profile4,
    //   review:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero reprehenderit tenetur voluptate? Nemo ad delectus doloremque aspernatur nostrum, accusantium odit, libero pariatur nesciunt illo harum quasi officiis tenetur molestias!",
    // },
  ];

  return (
    <div className="t-wrapper" id="customers">
      <div className="t-heading">
        <span>Our Customers</span>
        <img
          className="smallBorder"
          style={{ width: "25%", objectFit: "cover" }}
          src={pr2}
          alt="pr2"
        />
      </div>
      <div className="clients-box">
        <Marquee direction="right" speed={50} delay={0}>
          <div className="image_wrapper">
            <img src={l1} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l2} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l3} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l4} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l5} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l6} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l7} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l8} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l9} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l10} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l11} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l12} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={l13} alt="" />
          </div>
        </Marquee>
      </div>
      <div className="t-heading">
        <span>Testimonials</span>
        <img
          className="smallBorder"
          style={{ width: "25%", objectFit: "cover" }}
          src={pr2}
          alt="pr2"
        />
      </div>
      {/* <img
        className="smallBorder"
        style={{ width: "5%", objectFit: "cover" }}
        src={pr2}
        alt="pr2"
      /> */}

      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{ delay: 3000 }}
        smooth
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop={true}
        navigation={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <animated.div ref={ref} style={{...props3}} className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
                <p className="testi-name">{client.name}</p>
                <p className="testi-designation">{client.designation}</p>
              </animated.div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <div
        style={{ top: "20%", left: "70%", background: "#c1f5ff" }}
        className="blur"
      ></div>
      <div style={{ top: "60%", left: "60%" }} className="blur"></div>
      <div style={{ top: "50%", left: "0%" }} className="blur"></div> */}

      <div style={{ height: "100px" }} class="wrp">
        <div class="portada"></div>
        <div class="contenido"></div>
        <div class="curveado">{/* <img src={curve2} /> */}</div>
      </div>
    </div>
  );
}
