import React, { useEffect, useState } from "react";
import "./hero.css";

import imgC1 from "../../img/1.png";
import imgC2 from "../../img/4.png";
import imgC3 from "../../img/2.png";
import imgC4 from "../../img/3.png";
import Marquee from "react-fast-marquee";

import l1 from "../../img/1.png";
import l2 from "../../img/2.png";
import l3 from "../../img/3.png";
import l4 from "../../img/4.png";
import l5 from "../../img/5.png";
import l6 from "../../img/6.png";
import l7 from "../../img/7.png";
import l8 from "../../img/8.png";
import l9 from "../../img/Natco-Logo.png";
import l10 from "../../img/bharat_forge.png";
import l11 from "../../img/solenis.png";
import l12 from "../../img/12_1.png";
import l13 from "../../img/sterlite1.png";
import part1 from "../../img/oracle-partner-network-logo.png";
import part2 from "../../img/sap-partner-logo.png";
import part3 from "../../img/ZendeskLogo.png";
import part4 from "../../img/SAP-SuccessFactors.png";

import mapImg from "../../img/worldmapbis.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Link } from "react-scroll";

import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

function Hero() {
  const [ref, inView] = useInView({ threshold: 0.01 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(20%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(100%)",
    },
    config: { duration: 1000 },
  });
  // const slides = [
  //   {
  //     image: imgC1,
  //     //   text: "Specialized in Contract Workforce Management (CWFM) Products",
  //   },
  //   {
  //     image: imgC2,
  //     //   text: "Virtual Workmen Onboarding and Labor Compliance for best ROI",
  //   },
  //   {
  //     image: imgC3,
  //     //   text: "Transformation of HCM through ",
  //     //   text2: "Verify Onboard (VYN) and CWFM",
  //   },
  //   {
  //     image: imgC4,
  //     //   text: "HCM powered by Automation for Higher Employee Engagement and Productivity",
  //   },
  // ];

  // const slides = [
  //   {
  //     video: vid1,
  //     // text: "Specialized in Contract Workforce Management (CWFM) Products",
  //   },
  //   {
  //     video: vid2,
  //     // text: "Virtual Workmen Onboarding and Labor Compliance for best ROI",
  //   },
  // ];

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [satisfiedUsersCount, setSatisfiedUsersCount] = useState(0);
  const [experiencedProfessionalsCount, setExperiencedProfessionalsCount] =
    useState(0);
  const [clients, setClients] = useState(0);

  const [satisfiedUsersRef, satisfiedUsersInView] = useInView({
    // triggerOnce: true,
  });

  const [experiencedProfessionalsRef, experiencedProfessionalsInView] =
    useInView({
      // triggerOnce: true,
    });

  const [clientsRef, clientsInView] = useInView({
    // triggerOnce: true,
  });

  // Use useEffect to start the counting animations when the elements are in view
  useEffect(() => {
    if (satisfiedUsersInView) {
      setSatisfiedUsersCount(200); // Set your target value here
    }
    if (experiencedProfessionalsInView) {
      setExperiencedProfessionalsCount(80); // Set your target value here
    }
    if (clientsInView) {
      setClients(400); // Set your target value here
    }
  }, [satisfiedUsersInView, experiencedProfessionalsInView, clientsInView]);

  return (
    <div id="Home" className="main-donation-d2">
      {/* <div className="overlay"></div> */}
      <a
        href="https://wa.me/+918618677877?text=Please%20write%20your%20query%20here%20and%20we%20will%20get%20back%20at%20the%20earliest"
        className="floating"
        target="_blank"
      >
        <i
          style={{ fontSize: "35px", marginTop: "3px" }}
          class="fa-brands fa-whatsapp"
        ></i>{" "}
      </a>
      {/* <h1 className="achievementHeading-d2 underline mb-5"></h1> */}
      <div className="achievementsMain-d2" id="Feats">
        <animated.div ref={ref} style={{...props}} className="achievementLeft-d2">
          {/* <div className="statusContainer1-d2" ref={satisfiedUsersRef}>
           
          </div> */}

          <div className="statusContainer2-d2">
            <div className="statusTexts-d2">
              <div className="statusHeader-d2">Transform Your ​</div>
              <div className="statusHeader-d2">Human Capital Management</div>
              <p className="statusPara-d2">
                With End-to-End Services from AmpliWorks​
              </p>
            </div>

            {/* <div
              // style={{ width: "rem", borderRadius: "10px" }}
              className="learnButton"
            >
              <p>Learn More</p>
            </div> */}

            <Link
              to="Solutions"
              smooth={true}
              spy={true}
              activeClass="activeClass"
            >
              <button className="learnButton">Learn More</button>
            </Link>
          </div>
        </animated.div>
        <div class="divider divider-horizontal"></div>
        <animated.div ref={ref} style={{...props}} className="achievementRight-d2">
          {/* <img
            style={{
              height: "120%",
              width: "100%",
              objectFit: "contain",
            }}
            src={ill1}
            alt=""
          /> */}

          <div className="carousal-container">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              autoplay={{ delay: 4000 }}
              smooth
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop={true}
              // onSlideChange={(swiper) => {
              //   setActiveSlideIndex(swiper.realIndex);
              // }}
            >
              <SwiperSlide>
                {/* <div className="imgContainer"></div> */}
                <div className="Header-in-swiper">
                  Global Presence. 24 X 7 Support.
                </div>
                <div className="world-map">
                  <img src={mapImg} alt="" />

                  <div className="pin usa">
                    <span>United States</span>
                  </div>
                  <div className="pin ind">
                    <span>India</span>
                  </div>
                  <div className="pin germany">
                    <span>Germany</span>
                  </div>
                  <div className="pin sa">
                    <span>South Africa</span>
                  </div>
                  <div className="rev">
                    <div className="pin uae">
                      <span>Middle East</span>
                    </div>
                  </div>
                  <div className="pin singapore">
                    <span>Singapore</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Header-in-swiper">Solution Experts of</div>
                <div className="box-container">
                  <div class="box">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <div class="content">
                      {/* <Marquee direction="right" speed={30} delay={0}>
                        <div className="image_wrapper">
                          <img src={l1} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l2} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l3} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l4} alt="" />
                        </div>
                      </Marquee>
                      <Marquee direction="right" speed={30} delay={0}>
                        <div className="image_wrapper">
                          <img src={l7} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l8} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l9} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l10} alt="" />
                        </div>
                      </Marquee>
                      <Marquee direction="right" speed={30} delay={0}>
                        <div className="image_wrapper">
                          <img src={l5} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l6} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l13} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l11} alt="" />
                        </div>
                        <div className="image_wrapper">
                          <img src={l12} alt="" />
                        </div>
                      </Marquee> */}
                      <div className="left-icons-cont">
                        <div className="left-icons">
                          <img src={part1} alt="" />
                        </div>
                        <div className="left-icons">
                          <img src={part2} alt="" />
                        </div>
                      </div>
                      <div className="left-icons-cont">
                        {/* <div className="left-icons">
                          <img src={part4} alt="" />
                        </div> */}
                        <div>
                          <img className="zendesk-image" src={part3} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {/* <div className="Header-in-swiper">
                  Global Presence. 24 X 7 Support.
                </div> */}
                <div className="main-h-achievement">
                  <div className="statusContainer1" ref={satisfiedUsersRef}>
                    <div className="statusIcon">
                      <i
                        className="fa-regular fa-face-smile"
                        style={{ color: "white", fontSize: "50px" }}
                      ></i>
                    </div>
                    <div className="statusIcon">
                      <i
                        className="fa-solid fa-people-roof"
                        style={{ color: "white", fontSize: "50px" }}
                      ></i>
                    </div>
                  </div>
                  <div className="statusContainer2">
                    <div className="statusTexts">
                      <div className="statusHeader-h">
                        {satisfiedUsersInView ? (
                          <CountUp
                            end={satisfiedUsersCount}
                            duration={2}
                            separator=","
                          />
                        ) : (
                          "0"
                        )}
                        K+
                      </div>
                      <p className="statusPara-h">Satisfied Users</p>
                    </div>
                    <div
                      className="statusTexts"
                      ref={experiencedProfessionalsRef}
                    >
                      <div className="statusHeader-h">
                        {experiencedProfessionalsInView ? (
                          <CountUp
                            end={experiencedProfessionalsCount}
                            duration={2}
                            separator=","
                          />
                        ) : (
                          "0"
                        )}
                        +
                      </div>
                      <p className="statusPara-h">
                        Team of experienced professionals with
                      </p>
                      {/* <p className="statusPara-h"></p> */}
                      <p className="statusPara-h">commitment beyond contract</p>
                    </div>
                    {/* <div className="statusTexts" ref={clientsRef}>
                    <div className="statusHeader-h">
                      {clientsInView ? (
                        <CountUp end={clients} duration={2} separator="," />
                      ) : (
                        "0"
                      )}
                      +
                    </div>
                    <p className="statusPara-h">
                      Clients on One to One Life Coaching
                    </p>
                  </div> */}
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>

            {/* <div className="stackContent">
          <div className="stackText">{slides[activeSlideIndex].text}</div>
          <div className="stackText2">{slides[activeSlideIndex].text2}</div>
        </div> */}
          </div>
        </animated.div>
      </div>
    </div>
  );
}

export default Hero;
