import React, { useEffect, useState } from "react";
import "./executives.css";
import pic1 from "../../img/profilePic1.png";
import pic2 from "../../img/profilePic2.jpg";
import pr2 from "../../img/smallBorder.png";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

function Executives() {
  const [ref, inView] = useInView({ threshold: 0.01 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(20%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(100%)",
    },
    config: { duration: 1000 },
  });
  return (
    <div className="main-exec-out">
      <h1 className="exec-header">
        Leadership
        <img
          className="smallBorder-c"
          style={{ width: "5%", objectFit: "cover" }}
          src={pr2}
          alt="pr2"
        />
      </h1>

      <div className="main-exec">
        <div className="exec-left"></div>
        <div className="exec-center">
          <div className="exec-left-in">
            <animated.div ref={ref} style={{ ...props }}  class="card-ex">
              <div class="card-text-ex">
                <div class="portada-ex">{/* <img src={pic1} alt="" /> */}</div>
                <div class="title-total-ex">
                  <div class="title-ex">Chief Executive Officer</div>
                  <h2>Hanuman Chittem</h2>

                  <div class="desc-ex">
                    Digital transformation catalyst in HR and CX for a very long
                    time with a penchant for a customer centric approach. With
                    more than 25 years of global experience, he started his
                    entrepreneurial journey. His Solution oriented mindset has
                    really helped AmpliWorks become a Solution experts for all
                    our clients.
                  </div>
                  <div class="actions-ex">
                    <div className="button-exec">
                      {/* <a
                        target="_blank"
                        href="https://www.linkedin.com/in/hanumanchittem"
                      >
                        {" "}
                        <p className="title-ex">Linkedin</p>
                      </a> */}

                      <ul class="sci-x">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/in/hanumanchittem"
                          >
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          </div>
          <div className="space"></div>
          <div className="exec-right-in">
            <animated.div  ref={ref} style={{...props}}  class="card-ex">
              <div class="card-text-ex">
                <div class="portada-ex-2">
                  {/* <img src={pic1} alt="" /> */}
                </div>
                <div class="title-total-ex">
                  <div class="title-ex">Chairman of the Board</div>
                  <h2>Gopal Amin</h2>

                  <div class="desc-ex">
                    A born leader and has been an Entrepreneur for more than 3
                    Decades.He has helped multiple companies in multifold
                    revenue growth like Kirloskar Copeland and SLK Global. Gopal
                    is passionate about mentoring young entrepreneurs to create
                    and scale value-based business.
                  </div>
                  <div class="actions-ex">
                    <div className="button-exec">
                      {/* <a
                        target="_blank"
                        href="https://www.linkedin.com/in/gopal-amin-b053a3a"
                      >
                        {" "}
                        <p className="title-ex">Linkedin</p>
                      </a> */}
                      <ul style={{ marginTop: "1.5rem" }} class="sci-x">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/in/gopal-amin-b053a3a"
                          >
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          </div>
        </div>
        <div className="exec-right"></div>
      </div>
      <div style={{ height: "100px" }} class="wrp">
        <div class="portada"></div>
        <div class="contenido"></div>
        <div class="curveado">{/* <img src={curve3} /> */}</div>
      </div>
    </div>
  );
}

export default Executives;
