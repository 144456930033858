import "./App.css";
import TopBar from "./Components/topbar/topbar";
import Hero from "./Components/landing_pages/hero";
import AboutUs from "./Components/about_us_page/aboutUs";
import WhoWeAre from "./Components/whoWeAre/whoWeAre";
import WhyUs from "./Components/whyUs/whyUs";
import Solutions_and_services from "./Components/solutions_and_services/solutions_and_service";
import Contact_us from "./Components/contact_us/contact_us";
import Footer from "./Components/Footer/Footer";
import Tie_ups from "./Components/tie_ups/tie_ups";
import Achievements from "./Components/achievements/achievements";
import Executives from "./Components/executives/executives";

import Hcm from "./Components/extraPages/hcm/hcm";
import DevelopmentServices from "./Components/extraPages/developmentServices/developmentServices";
import Sap from "./Components/extraPages/sap/sap";
import Intelligent from "./Components/extraPages/intelligent/intelligent";
import Kronos from "./Components/extraPages/kronos/kronos";
import Job1 from "./Components/extraPages/job1/job1";
import Job2 from "./Components/extraPages/job2/job2";
import Job3 from "./Components/extraPages/job3/job3";
import Customers from "./Components/customers/customers"
import Careers from "./Components/careers/careers"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/hcm" element={<Hcm />} />
          <Route path="/devServices" element={<DevelopmentServices />} />
          <Route path="/sap" element={<Sap />} />
          <Route path="/intelligent" element={<Intelligent />} />
          <Route path="/kronos" element={<Kronos />} />
          <Route path="/job1" element={<Job1 />} />
          <Route path="/job2" element={<Job2 />} />
          <Route path="/job3" element={<Job3 />} />
        </Routes>
      </Router>
    </div>
  );
}

const HomePage = () => (
  <div>
    <TopBar />
    <Hero />
    {/* <Tie_ups /> */}
    <AboutUs />
    <Executives />
    <Customers />
    {/* <Achievements /> */}
    {/* <WhoWeAre /> */}
    {/* <WhyUs /> */}
    <Solutions_and_services />
    <Careers />
    <Contact_us />
    <Footer />
  </div>
);

export default App;
