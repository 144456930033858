import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import "../hcm/hcm.css";
export default function Intelligent() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
       
      />
      <HcmData
        heading="Intelligent Automation
        "
        text="AmpliWorks provides Intelligent Automation services across Test Automation and RPA. The objective is to increase Employee Engagement & improve Productivity. We adopt a holistic approach towards application testing across mobile, web, desktop, embedded platforms and microservices. Our services allow you to define, build, implement and migrate test automation tools and automated test scripts."
      />

      <div className="normText">• Up-to 100% test coverage</div>
      <div className="normText">• Early defect detection</div>
      <div className="normText">• Tool-agnostic solutions</div>
      <div className="normText">• Reduced cycle time</div>
      <Contact_us />
      <Footer />
    </div>
  );
}
