import React, { useState } from "react";
import "./solutions_and_services.css";
import pr2 from "../../img/smallBorder.png";
import ic1 from "../../img/Group.png";
import ic2 from "../../img/Vector.png";
import ic3 from "../../img/Vector2.png";
import ic1w from "../../img/gr2w.png";
import ic2w from "../../img/v1w.png";
import ic3w from "../../img/v2w.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const Solutions_and_services = () => {
  const [ref, inView] = useInView({ threshold: 0.1 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(20%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(100%)",
    },
    config: { duration: 1000 },
  });
  const [isHovered1, setIsHovered1] = useState(true);

  const handleHover1 = () => {
    setIsHovered1(!isHovered1);
  };

  const [isHovered2, setIsHovered2] = useState(true);

  const handleHover2 = () => {
    setIsHovered2(!isHovered2);
  };
  const [isHovered3, setIsHovered3] = useState(true);

  const handleHover3 = () => {
    setIsHovered3(!isHovered3);
  };

  const [isHovered4, setIsHovered4] = useState(true);

  const handleHover4 = () => {
    setIsHovered4(!isHovered4);
  };
  const [isHovered5, setIsHovered5] = useState(true);

  const handleHover5 = () => {
    setIsHovered5(!isHovered5);
  };

  return (
    <animated.div ref={ref} id="Solutions" className="mainContainer">
      <div style={{ ...props }} className="mainHeader">
        SOLUTIONS & SERVICES
      </div>
      {/* <div className=""> */}
      <img
        className="smallBorder"
        style={{ width: "5%", objectFit: "cover" }}
        src={pr2}
        alt="pr2"
      />
      {/* </div> */}
      <div className="firstRow">
        <animated.a className="hyperLink-card" href="/kronos">
          <div
            data-tooltip-id="my-tooltip-1"
            className="cardMain"
            onMouseEnter={handleHover5}
            onMouseLeave={handleHover5}
          >
            <div className="icon">
              <img src={isHovered5 ? ic3 : ic2w} alt="" />
            </div>
            <h2 className="headerText">SERVICE FOR </h2>
            <h2 className="headerText2">UKG KRONOS</h2>
            <div className="points-cont">
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">End to End services </span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">for UKG / Kronos</span>
              </div>
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Complete CWFM / WFM</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">Solutions</span>
              </div>
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2"> 200k+ delighted users</span>
              </div>
            </div>

            {/* <a
             
              className="hyperLink"
              href="/kronos"
            >
              Learn More
            </a> */}
          </div>
        </animated.a>
        <ReactTooltip
          id="my-tooltip-1"
          place="bottom"
          variant="info"
          content="click to learn more"
          
          
        />
        <animated.a className="hyperLink-card" href="/hcm">
          <div
            data-tooltip-id="my-tooltip-1"
            className="cardMain"
            onMouseEnter={handleHover1}
            onMouseLeave={handleHover1}
          >
            <div className="icon">
              <img src={isHovered1 ? ic1 : ic1w} alt="" />
            </div>
            <h2 className="headerText">HCM</h2>
            <h2 className="headerText2">TRANSFORMATION</h2>
            {/* <p className="paraText">• Kronos/UKG</p> */}

            <div className="points-cont">
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Oracle HCM</span>
              </div>

              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">SAP Success Factors</span>
              </div>

              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2"> HCM insights powered</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom"> by Power BI</span>
              </div>
            </div>

            {/* <a className="hyperLink" href="/hcm">
              Learn More
            </a> */}
          </div>
        </animated.a>
        {/* <div className="cardMain" onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
                    <div className="icon">
                        <img src={isHovered2 ? ic3 : ic2w} alt="" />
                    </div>
                    <h2 className="headerText">CONTRACTOR WORKFORCE ONBOARDING (VYN)</h2>
                    <p className="paraText">
                        Lorem Ipsum is simply dummy
                        text of the printing and
                        typesetting industry.
                    </p>
                    <a className="hyperLink">Learn More</a>
                </div> */}

        <animated.a className="hyperLink-card" href="/sap">
          <div
            data-tooltip-id="my-tooltip-1"
            className="cardMain"
            onMouseEnter={handleHover3}
            onMouseLeave={handleHover3}
          >
            <div className="icon">
              <img src={isHovered3 ? ic2 : ic3w} alt="" />
            </div>
            <h2 className="headerText">SAP ECC or</h2>
            <h2 className="headerText2">S4 HANA</h2>
            <div className="points-cont">
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Implementation services</span>
              </div>

              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Accelerated S4 HANA</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom"> Migration</span>
              </div>

              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2"> DevOps</span>
              </div>
            </div>

            {/* <a className="hyperLink" href="/sap">
              Learn More
            </a> */}
          </div>
        </animated.a>
      </div>
      <div className="secondRow">
        <animated.a className="hyperLink-card" href="/devServices">
          <div
            data-tooltip-id="my-tooltip-1"
            className="cardMain"
            onMouseEnter={handleHover2}
            onMouseLeave={handleHover2}
          >
            <div className="icon">
              <img src={isHovered2 ? ic3 : ic2w} alt="" />
            </div>
            <h2 className="headerText">DEVELOPMENT</h2>
            <h2 className="headerText2">SERVICES</h2>

            <div className="points-cont">
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Cloud & Mobile App</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">Development</span>
              </div>
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Cloud Migration &</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">DevOps</span>
              </div>
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2"> Unified UX Design</span>
              </div>
            </div>

            {/* <a className="hyperLink" href="/devServices">
              Learn More
            </a> */}
          </div>
        </animated.a>
        <animated.a className="hyperLink-card" href="/intelligent">
          <div
            data-tooltip-id="my-tooltip-1"
            className="cardMain"
            onMouseEnter={handleHover4}
            onMouseLeave={handleHover4}
          >
            <div className="icon">
              <img src={isHovered4 ? ic1 : ic1w} alt="" />
            </div>
            <h2 className="headerText">INTELLIGENT</h2>
            <h2 className="headerText2">AUTOMATION</h2>

            <div className="points-cont">
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Intelligent Automation</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">Services</span>
              </div>
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">Test Automation across</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">platforms</span>
              </div>
              <div className="text-solution-cont">
                <span className="bullet-text">•</span>
                <span className="paraText2">RPA to increase Accuracy</span>
              </div>
              <div className="text-solution-cont">
                <span className="paraTextBottom">and Quality</span>
              </div>
            </div>

            {/* <a className="hyperLink" href="/intelligent">
              Learn More
            </a> */}
          </div>
        </animated.a>
      </div>
      <div style={{ height: "100px" }} class="wrp">
        <div class="portada"></div>
        <div class="contenido"></div>
        <div class="curveado">{/* <img src={curve3} /> */}</div>
      </div>
    </animated.div>
  );
};

export default Solutions_and_services;
