import React, { Component } from "react";
import "./topbar.css";
import webLogo from "../../img/Ampliworks logo 230725-01 1.png";
import { MenuItems } from "./menuItems";
import { Link } from "react-scroll";

class Navbar extends Component {
  state = { clicked: true };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleMenuClick = () => {
    this.setState({ clicked: true });
  };

  render() {
    return (
      <nav className="navbar-Items">
        <div className="nav-bottom">
          <h1 className="navbar-Logo">
            <img height={30} src={webLogo} alt="logo" />
          </h1>
          <div className="menu-Icons" onClick={this.handleClick}>
            <i
              class={
                this.state.clicked ? "fa-solid fa-bars" : "fa-solid fa-xmark"
              }
            ></i>
          </div>

          <ul
            className={
              this.state.clicked ? "navbar-Menu" : "navbar-Menu active"
            }
          >
            {MenuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    activeStyle={{
                      textDecoration: "none",
                      borderBottom: "4px solid #fff",
                      fontWeight: 700,
                      fontFamily: "Poppins, sans-serif",
                    }}
                    to={item.toName}
                    smooth={true}
                    spy={true}
                    activeClass="activeClass"
                    offset={-window.innerHeight / 8}
                  >
                    <a
                      className={item.cName}
                      href={item.url}
                      onClick={this.handleMenuClick}
                    >
                      {item.title}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div
            
            className="right-side-cont"
          >
            <div className="phEmailCont">
              <i
                className="fa-solid fa-phone"
                style={{ color: "white", fontSize: "14px",marginTop:'0.3rem'  }}
              ></i>
              <p
                style={{ color: "white", fontSize: "14px" }}
                className="phEmail"
              >
                +1-320-853-3351
              </p>
            </div>

            <div className="phEmailCont">
              <i
                class="fa-solid fa-envelope"
                style={{ color: "white", fontSize: "14px",marginTop:'0.3rem' }}
              ></i>
              <a
                href="mailto:sales@ampliworks.com"
                style={{ textDecoration: "none", listStyle: "none" }}
              >
                <p
                  style={{ color: "white", fontSize: "14px" }}
                  className="phEmail"
                >
                  sales@ampliworks.com
                </p>
              </a>
            </div>
          </div>
          {/* <button className="req-button">Request Demo</button> */}
        </div>
      </nav>
    );
  }
}

export default Navbar;
