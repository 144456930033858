import React from "react";
import "./aboutUs.css";
import pr2 from "../../img/ampliBlob.png";
import pr3 from "../../img/smallBorder.png";
// import pr4 from "../../img/Ampliworks logo 230725-01 2.png";
// import pr5 from '../../img/pr5.jpg'
// import pr6 from '../../img/pr6.png'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, Pagination, Autoplay } from 'swiper'
// import 'swiper/css'
// import 'swiper/css/pagination'
// import 'swiper/css/navigation'
// import 'swiper/css/autoplay'
// import wel1 from "../../img/welmington.jpg";
import wel1 from "../../img/delaware.png";
import wel2 from "../../img/Strategic consulting-pana.png";
import wel3 from "../../img/At the office-amico.png";
import img1 from "../../img/pexels-elevate-digital-1647919.jpg";
import img2 from "../../img/pexels-alex-green-5699480.jpg";
// import img3 from "../../img/pexels-edmond-dantès-4342124.jpg";
import img3 from "../../img/hr_image.jpg";
import vec1 from "../../img/health_vector.jpg";
import vec2 from "../../img/manufacturevector.jpg";
import vec3 from "../../img/it_vector.jpg";
import vec4 from "../../img/services.jpg";
import vec5 from "../../img/6505516.jpg";
import vec6 from "../../img/supply_chain_vector.jpg";

import { Link } from "react-scroll";

import curve1 from "../../img/img2.svg";
import curve2 from "../../img/img1.svg";
import curve3 from "../../img/img3.svg";
import { useContext } from "react";

import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const AboutUs = () => {
  const [ref, inView] = useInView({ threshold: 0.01 });

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(20%)",
    },
    config: { duration: 1000 },
  });

  const props3 = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateY(0)" : "translateY(100%)",
    },
    config: { duration: 1000 },
  });
  return (
    <div
      id="AboutUs"
      className="aboutUsMain"
      //   style={{ display: "flex", flexDirection: "row" }}
    >
      {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}
      <div class="wrp">
        <div class="portada"></div>
        <div class="contenido">
          {/* <div class="info"> */}
          {/* <h2>About Us</h2> */}
          {/* <h1>
              <h1> Welcome to Ampliworks Technologies,</h1>

              <h1>
                a dynamic force in the realm of Human Capital Management (HCM)
                transformation.
              </h1>
              <h1>
                Our extensive proficiency spans across key industry sectors,
                including
              </h1>
              <h1>
                Manufacturing, Healthcare, Retail, Hospitality, Services, and
                Distribution.
              </h1>
              <h1>We offer a comprehensive suite of services, encompassing</h1>
              <h1>strategic consulting, implementation, integration,</h1>
              <h1>
                cloud migration, customization, support, and user training.
              </h1> */}
          {/* AmpliWorks is a USA Headquartered customer-centric company that
              specializes in HR Tech space. We have been delivering complex and
              path breaking implementations for our global clientele over the
              last 10 years. We are the preferred partners of SAP,
              SuccessFactors, ZenDesk and UKG. We have an expert team of techno
              functional experts who will guide you in strategic ERP
              implementations, HCM transformation initiatives, Managed services,
              Cloud Migrations and Roll Outs. */}
          {/* </h1> */}
          {/* <Link
              style={{ marginTop: "2rem",cursor:'pointer' }}
              className="contenido_button"
              to="ContactUs"
              smooth={true}
              spy={true}
              offset={-window.innerHeight / 8}
            >
              Contact Us
            </Link> */}
          {/* </div> */}
        </div>
        <div class="curveado">{/* <img src={curve1} /> */}</div>
      </div>

      <div className="about-bottom">
        <div
          className="aboutUsLeft"
          // style={{ backgroundColor: "#E7E7E7" }}
        ></div>
        <div ref={ref} className="aboutUsCentre">
          <h1 className="aboutUsHeader">
            About Us
            <img
              className="smallBorder-c"
              style={{ width: "8%", objectFit: "cover" }}
              src={pr3}
              alt="pr2"
            />
          </h1>
          <div className="about-us-centre-in-2">
            <animated.div
              ref={ref}
              style={{ ...props }}
              className="about-us-in-left"
            >
              {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}
              <div className="vector-img-cont">
                <div className="vector-cont-indi">
                  <div className="t-image">
                    <img className="vector-img" src={vec1} alt="" />
                  </div>
                  <span>Healthcare</span>
                </div>
                <div className="vector-cont-indi">
                  <div className="t-image">
                    <img className="vector-img" src={vec2} alt="" />
                  </div>
                  <span>Manufacturing</span>
                </div>
                <div className="vector-cont-indi">
                  <div className="t-image">
                    <img className="vector-img" src={vec3} alt="" />
                  </div>
                  <span>IT Sector</span>
                </div>
                <div className="vector-cont-indi">
                  <div className="t-image">
                    <img className="vector-img" src={vec4} alt="" />
                  </div>
                  <span>Services</span>
                </div>
                <div className="vector-cont-indi">
                  <div className="t-image">
                    <img className="vector-img" src={vec5} alt="" />
                  </div>
                  <span>Retail</span>
                </div>
                <div className="vector-cont-indi">
                  <div className="t-image">
                    <img className="vector-img" src={vec6} alt="" />
                  </div>
                  <span>Supply Chain</span>
                </div>
              </div>
              {/* <img className="illust-img" src={img1} alt="" /> */}
            </animated.div>
            <animated.div
              ref={ref}
              style={{ paddingLeft: "2rem", ...props }}
              className="about-us-in-right"
            >
              {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}

              <div className="aboutUsText">
                <p
                  style={{
                    wordWrap: "break-word", // Wrap long words to avoid overflow
                    whiteSpace: "normal",
                    // Allow text to wrap and break lines
                  }}
                >
                  AmpliWorks is a USA (Wilmington, Delaware) Headquartered
                  customer-centric company that specializes in HR Tech space. We
                  have been delivering complex and path breaking implementations
                  for our global clientele. We are the
                  Solution experts of SAP, SuccessFactors, ZenDesk and UKG. We
                  have an expert team of techno functional experts who will
                  guide you in strategic ERP implementations, HCM transformation
                  initiatives, Managed services, Cloud Migrations and Roll Outs.
                </p>
              </div>
            </animated.div>
          </div>
          <Link
            style={{
              marginTop: "0rem",
              cursor: "pointer",
              width: "20%",
              borderRadius: "10px",
              alignSelf: "center",
            }}
            className="req-button"
            to="ContactUs"
            smooth={true}
            spy={true}
            offset={-window.innerHeight / 8}
          >
            Contact Us
          </Link>
          {/* <div className="about-us-centre-in-2">
            <div className="about-us-in-left">

              <div className="aboutUsText">
                <p
                  style={{
                    fontSize: "1rem",
                    wordWrap: "break-word", // Wrap long words to avoid overflow
                    whiteSpace: "normal", // Allow text to wrap and break lines
                  }}
                >
                  Headquartered in the USA (Wilmington, Delaware) and with a
                  strategic presence in Bangalore, India, AmpliWorks
                  Technologies operates seamlessly on a global scale, ensuring
                  24/7 support to our clients. As a fully-owned subsidiary of
                  Onusworks Software, established in 2013, we bring a wealth of
                  experience and a proven track record of delivering superior
                  ROI to leading companies across diverse industries.
                </p>
              </div>
            </div>
            <div className="about-us-in-right">

              <img className="images-about-us" src={wel1} alt="" />
            </div>
          </div> */}
          <div className="about-us-centre-in">
            <animated.div
              ref={ref}
              style={{ ...props }}
              className="about-us-in-left"
            >
              {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}

              <img className="illust-img" src={img1} alt="" />
            </animated.div>
            <animated.div
              ref={ref}
              style={{ paddingLeft: "2rem", ...props }}
              className="about-us-in-right"
            >
              {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}

              <div className="aboutUsText">
                <p
                  style={{
                    wordWrap: "break-word", // Wrap long words to avoid overflow
                    whiteSpace: "normal",
                    // Allow text to wrap and break lines
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "600",
                      paddingBottom: "1rem",
                      wordWrap: "break-word", // Wrap long words to avoid overflow
                      whiteSpace: "normal", // Allow text to wrap and break lines
                    }}
                  >
                    {" "}
                    Key Strength :
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      wordWrap: "break-word", // Wrap long words to avoid overflow
                      whiteSpace: "normal", // Allow text to wrap and break lines
                    }}
                  >
                    <span
                      style={{
                        color: "#FF7F51",
                        fontSize: "20px",
                        marginLeft: "-0.8rem",
                        wordWrap: "break-word", // Wrap long words to avoid overflow
                        whiteSpace: "normal", // Allow text to wrap and break lines
                      }}
                    >
                      •
                    </span>{" "}
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        color: "#FF7F51",
                      }}
                    >
                      Transforming Human Capital Management{" "}
                    </span>
                    - Harnessing the capabilities of UKG/Kronos, Oracle HCM, and
                    SAP Success Factors.
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      wordWrap: "break-word", // Wrap long words to avoid overflow
                      whiteSpace: "normal", // Allow text to wrap and break lines
                    }}
                  >
                    <span
                      style={{
                        color: "#FF7F51",
                        fontSize: "20px",
                        marginLeft: "-0.8rem",
                        wordWrap: "break-word", // Wrap long words to avoid overflow
                        whiteSpace: "normal", // Allow text to wrap and break lines
                      }}
                    >
                      •
                    </span>{" "}
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        color: "#FF7F51",
                      }}
                    >
                      Driving Digital Transformation
                    </span>
                    - Empowering businesses through cloud and mobile app
                    development.
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      wordWrap: "break-word", // Wrap long words to avoid overflow
                      whiteSpace: "normal", // Allow text to wrap and break lines
                    }}
                  >
                    <span
                      style={{
                        color: "#FF7F51",
                        fontSize: "20px",
                        marginLeft: "-0.8rem",
                        wordWrap: "break-word", // Wrap long words to avoid overflow
                        whiteSpace: "normal", // Allow text to wrap and break lines
                      }}
                    >
                      •
                    </span>{" "}
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        color: "#FF7F51",
                      }}
                    >
                      Optimizing Return on Investment{" "}
                    </span>
                    - Expert implementations of SAP ECC and S/4 HANA.
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      wordWrap: "break-word", // Wrap long words to avoid overflow
                      whiteSpace: "normal", // Allow text to wrap and break lines
                    }}
                  >
                    <span
                      style={{
                        color: "#FF7F51",
                        fontSize: "20px",
                        marginLeft: "-0.8rem",
                        wordWrap: "break-word", // Wrap long words to avoid overflow
                        whiteSpace: "normal", // Allow text to wrap and break lines
                      }}
                    >
                      •
                    </span>{" "}
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        color: "#FF7F51",
                      }}
                    >
                      Enhancing Employee Engagement and Productivity{" "}
                    </span>
                    - Elevating employee engagement and boosting productivity
                    through HCM driven by automation.
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      wordWrap: "break-word", // Wrap long words to avoid overflow
                      whiteSpace: "normal", // Allow text to wrap and break lines
                    }}
                  >
                    <span
                      style={{
                        color: "#FF7F51",
                        fontSize: "20px",
                        marginLeft: "-0.8rem",
                        wordWrap: "break-word", // Wrap long words to avoid overflow
                        whiteSpace: "normal", // Allow text to wrap and break lines
                      }}
                    >
                      •
                    </span>{" "}
                    <span
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "700",
                        color: "#FF7F51",
                      }}
                    >
                      {" "}
                      Unveiling HCM Insights
                    </span>
                    - Empowered by Advanced Analytics with Power BI
                  </p>{" "}
                </p>
              </div>
            </animated.div>
          </div>
          {/* <div className="about-us-centre-in-2">
            <div style={{ paddingRight: "2rem" }} className="about-us-in-left">
              <div className="aboutUsText">
                <p
                  style={{
                    wordWrap: "break-word", // Wrap long words to avoid overflow
                    whiteSpace: "normal", // Allow text to wrap and break lines
                  }}
                >
                  Whether you have a tactical or a transformational business
                  need, our services enable you to embark on your journey
                  swiftly. Our consultants are adept at identifying risks,
                  leakages, and opportunities for productivity improvement. With
                  a cost-effective and proven services model, our expert team
                  ensures the delivery of the best ROI for your business.
                </p>
              </div>
            </div>
            <div className="about-us-in-right">

              <img className="illust-img-2" src={img2} alt="" />
            </div>
          </div> */}

          <div className="about-us-centre-in-3">
            <animated.div
              ref={ref}
              style={{ paddingLeft: "0rem", ...props }}
              className="about-us-in-left"
            >
              <div style={{marginLeft:'2rem'}} className="aboutUsText">
                <p
                  style={{
                    wordWrap: "break-word", // Wrap long words to avoid overflow
                    whiteSpace: "normal", // Allow text to wrap and break lines
                  }}
                >
                  Our HR domain and UKG/Kronos solution experts possess proven
                  experience in efficiently implementing Workforce Management
                  Solutions (WFM) by leveraging industry-specific best
                  practices. We specialize in the seamless integration of
                  Contract Workforce Management (CWFM), Standard Compliance
                  Report (CLRA), and related support services. Additionally, we
                  excel in integrating platforms such as{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    Dell Boomi
                  </span>
                  ,{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    WIM
                  </span>
                  , or{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    {" "}
                    SAP CPI
                  </span>{" "}
                  with other{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "bolder",
                    }}
                  >
                    3rd party ERP systems.
                  </span>
                </p>
              </div>
              {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}
            </animated.div>
            <animated.div
              ref={ref}
              style={{ ...props }}
              className="about-us-in-right"
            >
              {/* <h1 className="aboutUsHeader">ABOUT US</h1> */}
              <img className="illust-img" src={img3} alt="" />
            </animated.div>
          </div>
          <Link
            style={{
              marginTop: "0rem",
              cursor: "pointer",
              width: "20%",
              borderRadius: "10px",
              alignSelf: "center",
            }}
            className="req-button"
            to="ContactUs"
            smooth={true}
            spy={true}
            offset={-window.innerHeight / 8}
          >
            Contact Us
          </Link>
        </div>
        <div ref={ref} className="aboutUsRight"></div>
      </div>
      <div className="space"></div>
      <div style={{ height: "100px" }} class="wrp">
        <div class="portada"></div>
        <div class="contenido"></div>
        <div class="curveado">{/* <img src={curve3} /> */}</div>
      </div>
    </div>
  );
};

export default AboutUs;
