export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-Links",
    toName: "Home",
  },
  {
    title: "About Us",
    url: "/",
    cName: "nav-Links",
    toName: "AboutUs",
  },
  // {
  //   title: "Who We Are",
  //   url: "/",
  //   cName: "nav-Links",
  //   toName: "WhoWeAre",
  // },
  {
    title: "Customers",
    url: "/",
    cName: "nav-Links",
    toName: "customers",
  },
  {
    title: "Solutions & Services",
    url: "/",
    cName: "nav-Links",
    toName: "Solutions",
  },
   {
    title: "Careers",
    url: "/",
    cName: "nav-Links",
    toName: "careers",
  },
  {
    title: "Contact Us",
    url: "/",
    cName: "nav-Links",
    toName: "ContactUs",
  },
];
