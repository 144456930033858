import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import "../hcm/hcm.css";
export default function Job2() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
      />
      <HcmData
        heading="Job title : Success Factors – Senior Consultant
        "
      />

      <div className="normText">Role : SF Solution Architect</div>
      <div className="normText">
        Experience : Atleast 8+ SF experience. Must have 3implementations and
        rollout experience
      </div>

      <HcmData
        heading="Primary Responsibilities:
        "
      />

      <div className="normText">
        · The Consultant will be driving the discussions with the clients in
        designing and delivering solutions to meet Client requirements using
        standard modules of SAP SF
      </div>
      <div className="normText">
        · Conduct requirements workshops, showcase standard SAP SF solution,
        prepare Fit-Gap analysis
      </div>
      <div className="normText">
        · Work with Project teams in delivering SAP SF modules based on the
        Fit-Gap analysis
      </div>
      <div className="normText">
        · The consultant will be leading the discussions with the business
        process teams, project sponsor and customer IT team, independently and
        suggest solution based on best practices.
      </div>
      <div className="normText">
        · The Consultant should be able to think creatively and propose
        solutions/ workarounds which are scalable, match and align with the
        overall product strategy.
      </div>

      <HcmData
        heading="Requirements:
        "
      />

      <div className="normText">· HR SME</div>
      <div className="normText">
        · Commendable experience in end-to-end implementations of SAP SF
      </div>
      <div className="normText">
        · Modules - Employee Central, Recruitment / RMK, Performance Management
        & Goal Management, LMS, Onboarding 2.0 (must be good at atleast
        3modules)
      </div>
      <div className="normText">
        · Experience in SF Integration with SAP Payroll, UKG and other systems.
      </div>
      <div className="normText">
        · Experience in working on the productized and Custom Integration
        techniques within SuccessFactors.
      </div>

      <Contact_us />
      <Footer />
    </div>
  );
}
