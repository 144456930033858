import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function Kronos() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
       
      />
      <HcmData
        heading="Services for UKG/Kronos
        "
        text="AmpliWorks has been providing end-to-end services for UKG/Kronos’ WFM solutions since
        2013 and has many delighted customers and 200K+ users. UKG/Kronos offers one of the
        industry&#39;s most powerful Workforce Management tools to manage and engage your entire
        workforce.
        "
      />

      <HcmData
        text="UKG’s WFM solutions are very differentiated - It fully automates Punch-to-Pay, with AI-
        powered recommendation including leave planning, alternate workman allocation,
        scheduling (to name a few), enabled with contactless systems, geo tracking based T&amp;A etc.,
        along with real-time &amp; accurate operational visibility. It also integrates with multiple HCM &amp;
        technology solutions (e.g., Oracle, Success Factors, Workday, Dell Boomi integration); All
        seamlessly experienced with 100% functionality on desktop, tablet or phone. The solutions
        are effective across all categories of workforce – white/blue collar, on-roll/contract, be it in
        factory/corporate office/warehouse/showroom/field employees etc. UKG/Kronos is a Global
        Leader in Workforce Management, serving over half of the Fortune 1000. The solution is
        available in 13 languages, and is being used by 42+ million people across 118 countries.
        AmpliWorks specializes in the seamless integration of Contract Workforce Management
        (CWFM), Standard Compliance Report (CLRA), and related support services, utilizing
        platforms like Dell Boomi, WIM, or SAP HANA Cloud with other 3rd party ERP systems."
      />
      <HcmData
        text="Ampliworks’ HR domain &amp; UKG solution experts have proven experience to implement WFM
solutions in an accelerated manner with minimum customizations leveraging industry
specific best practices. We integrate seamlessly using Dell Boomi or WIM or SAP HANA Cloud
with other 3rd party ERP systems."
      />

      <Contact_us />
      <Footer />
    </div>
  );
}
