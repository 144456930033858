import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import "../hcm/hcm.css";
export default function Job1() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
      />
      <HcmData
        heading="Job Description: Integration Consultant (Dell Boomi, SAP CPI, SQL, RDBMS)
        "
        text="Position Overview:

        We are seeking a skilled and experienced Integration Consultant with expertise in Dell Boomi, SAP CPI (Cloud Platform Integration), SQL, and RDBMS (Relational Database Management Systems) to join our team. The Integration Consultant will be responsible for designing, developing, and implementing integration solutions that connect various systems and applications using Dell Boomi, SAP CPI, SQL, and RDBMS technologies. The ideal candidate should have a strong background in integration technologies, hands-on experience with Dell Boomi, SAP CPI, SQL, and RDBMS, and a deep understanding of enterprise integration patterns, best practices, and database concepts."
      />
      <HcmData
        heading="Key Responsibilities:
        "
      />

      <div className="normText">
        1. Collaborate with clients and stakeholders to understand their
        integration requirements, business processes, and data models.
      </div>
      <div className="normText">
        2. Design and develop end-to-end integration solutions using Dell Boomi,
        SAP CPI, SQL, and RDBMS to connect diverse systems, such as SAP,
        cloud-based applications, databases, and third-party systems.
      </div>
      <div className="normText">
        3. Perform comprehensive analysis of existing systems, interfaces, and
        data structures to identify integration opportunities and challenges.
      </div>
      <div className="normText">
        4. Define integration strategies, architectures, and frameworks to
        ensure efficient and scalable integration solutions.
      </div>
      <div className="normText">
        5. Configure and customize Dell Boomi and SAP CPI components,
        connectors, and mappings to enable seamless data flow and system
        interoperability.
      </div>
      <div className="normText">
        6. Develop and implement integration interfaces, APIs, and data
        transformation mappings using Dell Boomi, SAP CPI, SQL, and RDBMS
        technologies and industry-standard integration patterns / protocols
        (such as REST, SOAP, JSON, XML).
      </div>
      <div className="normText">
        7. Collaborate with cross-functional teams, including developers,
        functional consultants, architects, and project managers, to ensure
        successful delivery of integration projects.
      </div>
      <div className="normText">
        8. Conduct thorough testing and troubleshooting of integration solutions
        to ensure their reliability, performance, and adherence to quality
        standards.
      </div>
      <div className="normText">
        9. Provide guidance and technical support to clients and internal teams
        throughout the project lifecycle.
      </div>
      <div className="normText">
        10. Stay updated with the latest integration technologies, industry
        trends, and best practices related to Dell Boomi, SAP CPI, SQL, and
        RDBMS.
      </div>
      <HcmData
        heading="Required Skills and Qualifications:
        "
      />

      <div className="normText">
        · Bachelor's degree in Computer Science, Information Technology, or a
        related field.
      </div>
      <div className="normText">
        · Proven experience (X years) as an Integration Consultant with
        expertise in Dell Boomi, SAP CPI, SQL, and RDBMS or similar integration
        platforms.
      </div>
      <div className="normText">
        · Strong understanding of enterprise integration patterns, best
        practices, and data integration concepts.
      </div>
      <div className="normText">
        · Hands-on experience with Dell Boomi and SAP CPI, including connector
        configuration, process design, and mapping development.
      </div>
      <div className="normText">
        · Proficiency in SQL and working knowledge of RDBMS concepts and
        database systems.
      </div>
      <div className="normText">
        · Experience with integration between SAP and non-SAP systems, including
        external databases, legacy systems, and third-party applications.
      </div>
      <div className="normText">
        · Familiarity with cloud-based integration technologies and platforms,
        including Dell Boomi AtomSphere and SAP CPI.
      </div>
      <div className="normText">
        · Excellent problem-solving and analytical skills, with the ability to
        troubleshoot complex integration issues and propose effective solutions.
      </div>
      <div className="normText">
        · Strong communication and interpersonal skills, with the ability to
        effectively collaborate with both technical and non-technical
        stakeholders.
      </div>
      <div className="normText">
        · Ability to work in a fast-paced environment, manage multiple
        priorities, and meet project deadlines.
      </div>

      <HcmData
        heading="Preferred Qualifications:
        "
      />

      <div className="normText">
        · Certification in Dell Boomi and SAP CPI integration technologies or
        relevant SAP certifications.
      </div>
      <div className="normText">
        · Knowledge of additional SAP modules and their integration points, such
        as SAP SuccessFactors, SAP CRM, SAP BW, etc.
      </div>
      <div className="normText">
        · Familiarity with Agile development methodologies and tools (e.g.,
        Scrum, JIRA).
      </div>
      <div className="normText">
        · Experience in performance tuning and optimization of integration
        solutions.
      </div>
      <div className="normText">
        · Experience with integration between SAP and non-SAP systems, including
        external databases, legacy systems, and third-party applications.
      </div>
      <div className="normText">
        · Strong understanding of databases, SQL, and data modeling principles.
      </div>
      <Contact_us />
      <Footer />
    </div>
  );
}
