import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import "../hcm/hcm.css";
export default function Job3() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
      />
      <HcmData
        heading="Job Description:

        "
      />
      <div className="normText">
        Title: Full Stack Developer (MERN, React/NodeJS)
      </div>
      <div className="normText">Duration: Full Time</div>
      <div className="normText">Location: Bangalore</div>
      <div className="normText">Available to join: Immediate Joiner</div>
      <HcmData
        heading="Summary:
        "
      />

      <div className="normText">
        We are looking for highly skilled programmers with experience building
        web applications in Java. Java Developers are responsible for
        understanding user requirements and business objectives, participating
        in estimation process, implementing application features and
        functionality, and recommending changes to existing Java-based
        applications, among other duties.
      </div>
      <div className="normText">
        • Consistently demonstrate clear technical judgment, innovation, and
        consideration for the workstreams’ objectives.
      </div>
      <div className="normText">
        • Able to Implement code, guide team members and debug code written by
        team members.
      </div>
      <div className="normText">
        • Self-starter, motivated, and actively learning and researching new
        methods of work and new technology.
      </div>
      <div className="normText">
        • Should have worked in agile project development model
      </div>

      <HcmData
        heading="Responsibilities:
        "
      />

      <div className="normText">
        • Developing and designing front-end web architecture.
      </div>
      <div className="normText">
        • Building interactive consumer data from multiple systems.
      </div>
      <div className="normText">
        • Define code architecture decisions to support high-performance
        products
      </div>
      <div className="normText">
        • Translate wireframes and PSD Designs into functional web applications.
      </div>
      <div className="normText">
        • Work with development teams and managers to ideate software solutions
      </div>
      <div className="normText">
        • Developing well-functioning databases and applications.
      </div>
      <div className="normText">• Test software to ensure efficiency.</div>
      <div className="normText">• Address and improve any technical issues</div>

      <HcmData
        heading="Skills:

        "
      />

      <div className="normText">
        • In-depth knowledge of NodeJS, ExpressJS, or Restify
      </div>
      <div className="normText">
        • Ability to bind UI elements to JavaScript object models.
      </div>
      <div className="normText">
        • Experience creating RESTful services with Node.js.
      </div>
      <div className="normText">
        • Proficiency in OOP and database concepts.
      </div>
      <div className="normText">
        • Proven experience in front & back-end development.
      </div>
      <div className="normText">
        • Ability to create front-end applications using HTML5, React Js, and
        CSS.
      </div>
      <div className="normText">
        • Knowledge of modern frameworks and design patterns.
      </div>
      <div className="normText">
        • Ability to create Unit and Integration tests to ensure the quality of
        code.
      </div>
      <div className="normText">• Good communication skills.</div>
      <div className="normText">• Strong analytical thinking.</div>

      <HcmData
        heading="Required Qualifications:

        "
      />

      <div className="normText">
        1. Should have 5+ years of experience with 2-3 years of experience on
        MERN stack development
      </div>
      <div className="normText">
        2. Minimum qualification required B. Tech, M. Tech or MCA
      </div>
      <div className="normText">
        3. Excellent written and verbal communication skills
      </div>

      <Contact_us />
      <Footer />
    </div>
  );
}
