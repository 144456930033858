import "./hcm.css";
import React, { useEffect, useRef } from "react";

export default function HcmData(props) {
  const hcmContainerRef = useRef(null);

  useEffect(() => {
    if (hcmContainerRef.current && props.scrollTo) {
      hcmContainerRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [props.scrollTo]);
  return (
    <div ref={hcmContainerRef} className="hcmContainer">
      <h1>{props.heading}</h1>
      <p>{props.text}</p>
      {props.isButton ? (
        // <a href="/">
        <button onClick={props.onClickButton} className="homeIconCont">
          <i class="fa-sharp fa-solid fa-circle-arrow-left"></i>
        </button>
      ) : (
        // </a>
        <div></div>
      )}
    </div>
  );
}