import HcmData from "../hcm/hcmData";
import Contact_us from "../../contact_us/contact_us";
// import TopBar from "../../topbar/topbar";
import Footer from "../../Footer/Footer";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function Sap() {
  const solutionsAndServicesRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to the solutionsAndServicesRef element if it exists
    if (solutionsAndServicesRef.current) {
      solutionsAndServicesRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [location]);

  const handleGoBack = () => {
    window.history.back(); // Go back in the browser's history
  };
  return (
    <div>
      {/* <TopBar /> */}
      <HcmData
        scrollTo={solutionsAndServicesRef}
        onClickButton={handleGoBack}
        isButton="Go Back"
       
      />
      <HcmData
        heading="Services for SAP Success Factors
        "
        text="Ampliworks’ provides services across the application lifecycle of SAP SF. We enable you to create, transform and sustain your SAP SF landscape to meet business objectives and engage your workforce. Our services include development, maintenance, modernization, migration, cloud enablement and consulting.
        "
      />

      <HcmData text="SAP SF is amongst the best Human Capital Management suites with 40+ million happy subscribers." />
      <Contact_us />
      <Footer />
    </div>
  );
}
